
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ModalRoleForm from "@/components/roles/RoleForm.vue";

export default defineComponent({
  name: "Roles",
  components: { ModalRoleForm },
  setup() {
    const rolesData: any = ref([]);
    const roleEdit = ref([]);
    const typeForm = ref("");

    const getRoleData = () => {
      setData();
    };

    const setData = () => {
      ApiService.get("api/roles")
        .then((result) => {
          if (result.data.success) {
            rolesData.value.length = 0;
            let response = result.data.data.role;
            for (let key in response) {
              let value = response[key];
              rolesData.value.push(value);
            }
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const editRole = (role) => {
      roleEdit.value = { ...role };
      typeForm.value = "edit";
    };

    const cleanEditRole = () => {
      typeForm.value = "new";
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Roles y permisos", []);
      setData();
    });

    return {
      rolesData,
      getRoleData,
      editRole,
      roleEdit,
      typeForm,
      cleanEditRole,
    };
  },
});
